<template>
  <div class="start-window">
    <div class="b21">{{ $t('USR_SAV') }}...</div>
  </div>
</template>

<script>
import { AUTH_SOCIAL_TOKENS } from "@/store/actions/auth";
import tokenMixin from '@/mixins/tokenMixin';

export default {
  name: "SocialAuth",
  mixins: [tokenMixin],
  data() {
    return {
      // authProcessing is now handled by the mixin
    };
  },
  mounted() {
    this.parseToken();
  },
  methods: {
    afterSaveAction() {
      console.log('window.close()', 1);
      // Uncomment the line below to close the window after authentication
      // window.close();
    },
  },
};
</script>